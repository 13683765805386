<!-- Material Designs "close" icon from https://www.iconfinder.com/icons/352270/close_icon -->
<template>
  <svg
    width="100%"
    height="100%"
    class="icon-close"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z" />
    <path
      d="M0 0h48v48h-48z"
      fill="none"
    />
  </svg>
</template>