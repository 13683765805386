import { getCountryOptions, getTypesOfServicesOptions } from "@/utils/Options"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

export function getSiteSchema() {
  return [
    {
      $formkit: "hidden",
      name: "id",
      value: "$id",
    },
    {
      $formkit: "text",
      id: "name",
      name: "name",
      value: "$name",
      label: $gettext("Name"),
      validation: "required",
    },
    {
      $formkit: "text",
      id: "state",
      name: "state",
      value: "$state",
      label: $gettext("State or province"),
    },
    {
      $formkit: "text",
      id: "city",
      name: "city",
      value: "$city",
      label: $gettext("City"),
    },
    {
      $formkit: "select",
      id: "country",
      name: "country",
      value: "$country",
      label: $gettext("Country"),
      options: getCountryOptions(),
      validation: "required",
      "inner-class": "select",
    },
    {
      $formkit: "checkbox",
      id: "typesOfServices",
      name: "typesOfServices",
      value: "$typesOfServices",
      label: $gettext("Types of Services"),
      options: getTypesOfServicesOptions(),
      validation: "required",
    },
  ]
}

export function getSiteSettingsSchema() {
  return [
    {
      $formkit: "checkbox",
      id: "growthEnabled",
      name: "growthEnabled",
      value: "$growthEnabled",
      label: $gettext("Should growth assessments be enabled?"),
      "outer-class": "single-checkbox",
    },
    {
      $formkit: "checkbox",
      id: "earlyidEnabled",
      name: "earlyidEnabled",
      value: "$earlyidEnabled",
      label: $gettext("Should developmental screenings be enabled?"),
      "outer-class": "single-checkbox",
    },
    {
      $formkit: "checkbox",
      if: "$get(earlyidEnabled).value",
      id: "areBehaviorAndEmotionRequired",
      name: "areBehaviorAndEmotionRequired",
      value: "$areBehaviorAndEmotionRequired",
      label: $gettext('Should the "Behavior and Emotion" portion of developmental screenings be required?'),
      "outer-class": "single-checkbox",
    },
    {
      $formkit: "checkbox",
      id: "isFeedingScreeningEnabled",
      name: "isFeedingScreeningEnabled",
      value: "$isFeedingScreeningEnabled",
      label: $gettext("Should feeding screenings be enabled?"),
      "outer-class": "single-checkbox",
    },
    {
      $formkit: "checkbox",
      id: "anemiaEnabled",
      name: "anemiaEnabled",
      value: "$anemiaEnabled",
      label: $gettext("Should anemia assessments be enabled?"),
      "outer-class": "single-checkbox",
    },
    // {
    //   $formkit: "checkbox",
    //   id: "isSiteMealtimeBestPracticeAssessmentEnabled",
    //   name: "isSiteMealtimeBestPracticeAssessmentEnabled",
    //   value: "$isSiteMealtimeBestPracticeAssessmentEnabled",
    //   label: $gettext("Should site-level feeding best practice assessments be enabled?"),
    //   "outer-class": "single-checkbox",
    // },
    {
      $formkit: "checkbox",
      id: "isChildMealtimeBestPracticeAssessmentEnabled",
      name: "isChildMealtimeBestPracticeAssessmentEnabled",
      value: "$isChildMealtimeBestPracticeAssessmentEnabled",
      label: $gettext("Should child-level best practice assessments be enabled?"),
      "outer-class": "single-checkbox",
    },
    {
      $formkit: "checkbox",
      id: "inTrainingMode",
      name: "inTrainingMode",
      value: "$inTrainingMode",
      label: $gettext("Should training mode be enabled?"),
      "outer-class": "single-checkbox",
    },
  ]
}
