<template>
  <div>
    <strong class="is-block">{{ child.name }}</strong>
    <em class="is-size-7">
      {{ $gettext("Assessments: %{ number }", { number: child.assessmentCount }) }}
    </em>
  </div>
  <a
    v-if="childStatus === 'none'"
    class="button is-responsive"
    @click="preserve"
  >
    {{ $gettext("Keep this child") }}
  </a>
  <IconSpinner v-else-if="childStatus === 'waiting'" />
  <div
    v-else
    class="message is-success"
  >
    <p class="message-body">
      {{ $gettext("Child will not be deleted.") }}
    </p>
  </div>
</template>
<script lang="ts" setup>
  import { ref } from "vue"

  import IconSpinner from "@/components/svg/IconSpinner.vue"
  import { markChildAsReal } from "@/services/Child"

  const props = defineProps({
    child: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    }
  })
  const emit = defineEmits(["removeChild"])
  const childStatus = ref("none")

  async function preserve() {
    childStatus.value = "waiting"
    markChildAsReal(props.site.cmiId, props.child.id)
      .then(() => {
        childStatus.value = "done"
        // Show success message for 2 seconds.
        setTimeout(() => { emit("removeChild", props.child.id) }, 2000)
      })
  }
</script>
<style scoped>
  svg {
    max-width: 1.5em;
    max-height: 1.5em;
  }
  .message.is-success {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    opacity: 90%;
    text-align: center;
  }
  p.message-body {
    margin-bottom: 0;
    font-weight: bold;
  }
</style>
