<script lang="ts" setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { setCurrentViewContext } from "@/utils/GlobalState"
import { isOnline } from "@/utils/Utilities"
import { getTypesOfServicesDisplay, getCountryDisplay } from "@/utils/Options"
import * as siteServices from "@/services/Site"
import { ISite } from "@/db"
import { gettext } from "@/utils/Translation"

import PanelLabelValuePair from "@/components/sites/PanelLabelValuePair.vue"
import IconPencil from "@/components/svg/IconPencil.vue"
import IconCheck from "@/components/svg/IconCheckCircled.vue"
import IconClose from "@/components/svg/IconClose.vue"
import PageLoading from "@/components/PageLoading.vue"
import SiteHeader from "@/components/sites/SiteHeader.vue"

const { $gettext } = gettext
const route = useRoute()
const router = useRouter()
const siteCmiId = parseInt(route.params.siteCmiId as string)
const site = ref({} as ISite)
const active = ref("details")
const canEdit = ref(false)
const siteAccountList = ref(false)

async function getSite() {
  const rawSite = await siteServices.getSiteByCmiId(siteCmiId)
  canEdit.value = await siteServices.canEditSite(rawSite)
  if (!rawSite) {
    router.replace({ name: "SiteList" })
  }
  site.value = rawSite
  setCurrentViewContext(rawSite, "site")
}

async function getSiteAccountList() {
  if (!siteAccountList.value && isOnline()) {
    siteAccountList.value = await siteServices.getSiteAccountList(site.value)
  }
}

function getSiteDetails() {
  const typesOfServices = site.value.typesOfServices?.map((item) => getTypesOfServicesDisplay(item))
  return [
    { label: $gettext("Name"), value: site.value.name },
    { label: $gettext("City"), value: site.value.city },
    { label: $gettext("State or province"), value: site.value.state },
    { label: $gettext("Country"), value: getCountryDisplay(site.value.country) },
    { label: $gettext("Timezone"), value: site.value.timezone },
    { label: $gettext("Types of Services"), value: typesOfServices },
  ]
}

function getSiteSettings() {
  const results = [
    { label: $gettext("Growth assessments"), value: Boolean(site.value.growthEnabled) },
    { label: $gettext("Feeding screenings"), value: Boolean(site.value.isFeedingScreeningEnabled) },
    { label: $gettext("Anemia assessments"), value: Boolean(site.value.anemiaEnabled) },
    { label: $gettext("Developmental screenings"), value: Boolean(site.value.earlyidEnabled) },
    { label: $gettext("Child-level best practices assessments"), value: Boolean(site.value.isChildMealtimeBestPracticeAssessmentEnabled) },
  ]
  if (site.value.earlyidEnabled) {
    results.push({
      label: $gettext('The "Behavior and Emotion" portion of developmental screenings is required'),
      value: Boolean(site.value.areBehaviorAndEmotionRequired),
    })
  }
  // Only show the best practice flavors that are relevant for this site based
  // on the "types of services" they offer.
  // const candidates = siteServices.getBestPracticeTypeCandidatesForSite(site.value)
  // if (candidates.includes("site")) {
  //   results.push(
  //     { label: $gettext("Site-level mealtime best practice assessments"), value: Boolean(site.value.isSiteMealtimeBestPracticeAssessmentEnabled) },
  //   )
  // }
  // if (candidates.includes("child")) {
  //   results.push(
  //     { label: $gettext("Child-level mealtime best practice assessments"), value: Boolean(site.value.isChildMealtimeBestPracticeAssessmentEnabled) },
  //   )
  // }
  results.push({
    label: $gettext("Training mode"),
    value: Boolean(site.value.inTrainingMode),
    isSeparate: true,
  })

  return results
}

getSite()
</script>

<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{ label: $gettext('Manage site') }"
    />
    <h1 class="title">
      {{ $gettext("Manage Site") }}
    </h1>
    <section
      v-if="site"
      class="panel is-primary"
    >
      <p class="panel-tabs">
        <a
          :class="{ 'is-active': active == 'details' }"
          @click="active = 'details'"
        >
          {{ $gettext("Details") }}
        </a>
        <a
          :class="{ 'is-active': active == 'settings' }"
          @click="active = 'settings'"
        >
          {{ $gettext("Settings") }}
        </a>
        <a
          :class="{ 'is-active': active == 'accounts' }"
          @click="active = 'accounts'; getSiteAccountList()"
        >
          {{ $gettext("Accounts") }}
        </a>
      </p>

      <template v-if="site && active == 'details'">
        <PanelLabelValuePair
          v-for="data in getSiteDetails()"
          :key="data.label"
          :label="data.label"
          :value="data.value"
        />

        <div
          v-if="canEdit"
          class="panel-block"
        >
          <router-link
            v-if="site.cmiId"
            class="button is-fullwidth is-medium is-responsive is-primary"
            :to="{ name: 'SiteEdit', params: { siteCmiId: site.cmiId } }"
          >
            <IconPencil />
            <span>{{ $gettext("Edit") }}</span>
          </router-link>
        </div>
      </template>

      <template v-if="active == 'settings'">
        <div
          v-for="data in getSiteSettings()"
          :key="data.label"
          :class="{ 'panel-block': true, setting: true, separate: data.isSeparate }"
        >
          <div class="column icon-column">
            <template v-if="data.value">
              <IconCheck :show-circle="false" />
            </template>
            <template v-else>
              <IconClose />
            </template>
          </div>
          <div class="column setting-column">
            {{ data.label }}
          </div>
        </div>
        <div
          v-if="canEdit && site.cmiId"
          class="panel-block"
        >
          <router-link
            class="button is-fullwidth is-medium is-responsive is-primary"
            :to="{ name: 'SiteSettings', params: { siteCmiId: site.cmiId } }"
          >
            <IconPencil />
            <span>{{ $gettext("Edit") }}</span>
          </router-link>
        </div>
        <div
          v-if="canEdit && site.inTrainingMode"
          class="panel-block"
        >
          <router-link
            class="button is-fullwidth is-medium is-responsive is-primary"
            :to="{ name: 'SiteDisableTrainingMode', params: { siteCmiId: site.cmiId } }"
          >
            <span>{{ $gettext("Review training data and disable training mode") }}</span>
          </router-link>
        </div>
      </template>

      <template v-if="active == 'accounts'">
        <template v-if="isOnline()">
          <PageLoading
            v-if="!siteAccountList"
            wrapper-classes="padded"
            :with-text="true"
          />
          <template v-else>
            <template v-if="siteAccountList.length">
              <PanelLabelValuePair
                v-for="account in siteAccountList"
                :key="account.id"
                :label="account.name"
                :value="account.roleLabel"
              />
            </template>
            <div
              v-else
              class="panel-block"
            >
              <p class="muted is-italic">
                {{ $gettext("There are no accounts associated with this site yet.") }}
              </p>
            </div>
          </template>
          <div
            v-if="canEdit"
            class="panel-block"
          >
            <router-link
              v-if="site.cmiId"
              class="button is-fullwidth is-medium is-responsive is-primary"
              :to="{ name: 'SiteAccounts', params: { siteCmiId: site.cmiId } }"
            >
              <IconPencil />
              <span>{{ $gettext("Manage Accounts & Roles") }}</span>
            </router-link>
          </div>
        </template>
        <div
          v-else
          style="padding: 1em"
        >
          <p>
            {{ $gettext("An internet connection is required for this page.") }}
          </p>
          <p>
            {{ $gettext("Your device does not seem to be connected to the internet. Please check your connection and try again by revisiting this page.") }}
          </p>
        </div>
      </template>
    </section>
  </article>
</template>
<style scoped>
  .setting.separate {
    border-top: 1px solid hsl(0, 0%, 71%);  /* Bulma's $grey-light*/
  }
  .setting svg {
    width: 2em;
  }
  .setting .column {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  .column.icon-column {
    flex: 0;
  }
  .column.setting-column {
    flex: 1;
  }
</style>
