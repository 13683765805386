<template>
  <section>
    <h2 :class="{ sticky: !isTabletOrWider }">
      {{ sectionTitle }}
    </h2>
    <table v-if="isTabletOrWider" class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ $gettext("Child") }}
          </th>
          <th scope="col">
            {{ $gettext("Reason") }}
          </th>
          <th scope="col">
            {{ $gettext("Refer by") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="referral in referrals"
          :key="referral.id"
        >
          <td>
            <strong>
              {{ referral.childName }}
            </strong>
            <router-link
              :to="referral.carePlanParams"
              class="text-link"
            >
              {{ $gettext("Care plan") }}
            </router-link>
          </td>
          <td>
            <ul class="list">
              <li
                v-for="item in referral.reason"
                :key="item.id"
              >
                {{ item.content }}
              </li>
            </ul>
            <router-link
              :to="referral.recsLinkParams"
              class="text-link"
            >
              {{ $gettext("Recommendations") }}
            </router-link>
          </td>
          <td>
            {{ referral.referralDate }}
          </td>
        </tr>
      </tbody>
    </table>
    <template v-else>
      <div
        v-for="referral in referrals"
        :key="referral.id"
        class="referral"
      >
        <div class="columns mb-0 is-mobile">
          <div class="column">
            <strong>
              {{ $gettext("Child") }}
            </strong>
            {{ referral.childName }}
            <router-link
              :to="referral.carePlanParams"
              class="text-link"
            >
              {{ $gettext("Care plan") }}
            </router-link>
          </div>
          <div class="column refer-by">
            <strong>
              {{ $gettext("Refer by") }}
            </strong>
            {{ referral.referralDate }}
          </div>
        </div>
        <strong>
          {{ $gettext("Reason") }}
        </strong>
        <ul class="list">
          <li
            v-for="item in referral.reason"
            :key="item.id"
          >
            {{ item.content }}
          </li>
        </ul>
        <router-link
          :to="referral.recsLinkParams"
          class="text-link"
        >
          {{ $gettext("Recommendations") }}
        </router-link>
      </div>
    </template>
  </section>
</template>
<script lang="ts" setup>
  import { computed } from "vue"
  import { useWindowSize } from "@vueuse/core"

  defineProps({
    referrals: {
      type: Array<object>,
      required: true,
    },
    sectionTitle: {
      type: String,
      required: true,
    }
  })

  const tabletMinWidth = 625 // Should correspond with $tablet defined in _main.css
  const { width } = useWindowSize()
  const isTabletOrWider = computed(() => width.value > tabletMinWidth)

</script>
<style lang="scss" scoped>
  .sticky {
    position: sticky;
    background-color: white;
    top: -1em; // Compensate for the top padding on <main>
    border-bottom: 2px solid rgb(214, 217, 224);
  }
  .text-link {
    font-size: 85%;
    display: block;
  }
  .referral strong {
    display: block;
  }
  .refer-by {
    flex-grow: 0;
  }
  .referral {
    border-bottom: 1px solid rgb(214, 217, 224);
    padding: 0.5em 0;
    margin:  0.5em 0;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .table {
    margin-bottom: 2em;
  }
</style>
