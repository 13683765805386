<script lang="ts" setup>
import { ref } from "vue"
import { useRouter, useRoute } from "vue-router"
import { setCurrentViewContext } from "@/utils/GlobalState"
import { gettext } from "@/utils/Translation"
import { getSiteByCmiId, addOrUpdateSite } from "@/services/Site"
import { canAddSite, getProjectByCmiId } from "@/services/Project"
import { getSiteSchema } from "@/schemas/Site"
import { ISite } from "@/db"

import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"
import SiteHeader from "@/components/sites/SiteHeader.vue"

const { $gettext } = gettext
const router = useRouter()
const route = useRoute()
// Only one of the following two will be defined.
const siteCmiId = parseInt(route.params.siteCmiId as string)
const projectCmiId = parseInt(route.params.projectCmiId as string)
const isNewSite = ref(false)
const pushSuccessRouteFunc = (site: ISite) => router.replace({ name: "SiteManage", params: { siteCmiId: site.cmiId } })
let formDataRetrievalFunc = null // Don't need one if we're creating.
const site = ref(null as ISite)

async function getProject() {
  const project = await getProjectByCmiId(projectCmiId)
  if (!project || !canAddSite(project)) {
    router.replace({ name: "SiteList" })
  }
  setCurrentViewContext(project, "project")
}

function uploadFunc(data) {
  if (projectCmiId) {
    data.projectCmiId = projectCmiId
  }
  else {
    data.cmiId = siteCmiId
  }
  return addOrUpdateSite(data)
}

if (siteCmiId) {
  formDataRetrievalFunc = async () => {
    const rawSite = await getSiteByCmiId(siteCmiId, { fromServer: true })
    setCurrentViewContext(rawSite, "site")
    site.value = rawSite
    return rawSite
  }
}
else {
  isNewSite.value = true
  getProject()
}
</script>

<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{
        label: $gettext('Manage site'),
        urlParams: { name: 'SiteManage', params: { siteCmiId } },
      }"
    />
    <OnlineRequiredForm
      :page-title="isNewSite ? $gettext('Add New Site') : $gettext('Edit Site')"
      :schema="getSiteSchema()"
      :form-data-retrieval-func="formDataRetrievalFunc"
      :upload-func="uploadFunc"
      :push-success-route-func="pushSuccessRouteFunc"
    />
  </article>
</template>
