<script setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { setCurrentViewContext } from "@/utils/GlobalState"
import { getAccount, changePassword } from "@/services/Account"
import { getPasswordSchema, togglePasswordVisibility } from "@/schemas/Account"
import { getSiteByCmiId } from "@/services/Site"
import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

const route = useRoute()
const router = useRouter()
const siteCmiId = parseInt(route.params?.siteCmiId)
const accountCmiId = parseInt(route.params?.accountCmiId)
const account = ref(null)
const successRoute = { name: "SiteHome", params: { siteCmiId } }

async function uploadFunc({ new_password}) {
  await changePassword({ password: new_password }, siteCmiId, accountCmiId)
}

async function getData() {
  const site = await getSiteByCmiId(siteCmiId)
  if (!site) {
    router.replace({ name: "SiteList" })
  }
  setCurrentViewContext(site, "site")
  account.value = await getAccount(siteCmiId, accountCmiId)
}

// An abuse of this function; we need to pass into the "data" prop of the form
// the function that handles clicks on the eye icon.
async function getFormData() {
  return { togglePasswordVisibility }
}

getData()
</script>

<template>
  <article>
    <OnlineRequiredForm
      :page-title="$gettext('Change password')"
      :schema="getPasswordSchema()"
      :form-data-retrieval-func="getFormData"
      :upload-func="uploadFunc"
      :success-route="successRoute"
    >
      <template #preface>
        <div v-if="account">
          <dl>
            <dt>
              {{ $gettext("You are setting the password for:") }}
            </dt>
            <dd>
              {{ account.firstName }} {{ account.lastName }}
              &lt;{{ account.email }}&gt;
            </dd>
          </dl>
        </div>
      </template>
    </OnlineRequiredForm>
  </article>
</template>
<style scoped>
  dd {
    text-transform: none;
  }
</style>
