<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute, useRouter } from "vue-router"

  import { setCurrentViewContext } from "@/utils/GlobalState"
  import {
    getSiteByCmiId,
    canEditSite,
    getTrainingModePurgeList,
    disableTrainingMode,
  } from "@/services/Site"
  import { showFlashMessage } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"
  import { ISite } from "@/db"

  import PageLoading from "@/components/PageLoading.vue"
  import SiteDisableTrainingModeChild from "@/components/sites/SiteDisableTrainingModeChild.vue"
  import SiteHeader from "@/components/sites/SiteHeader.vue"
  import IconSpinner from "@/components/svg/IconSpinner.vue"

  const { $gettext } = gettext
  const route = useRoute()
  const router = useRouter()
  const siteCmiId = parseInt(route.params.siteCmiId as string)
  const pageReady = ref(false)
  const purgeList = ref(null)
  const site = ref(null as ISite)
  const hasPurgeData = ref(false)
  const formSubmitted = ref(false)

  function removeChildFromPurgeList(id: number) {
    const index = purgeList.value.children.findIndex((c) => c.id === id)
    purgeList.value.children.splice(index, 1)
  }

  function purgeAndDisableTrainingMode() {
    // Swap in a spinner
    formSubmitted.value = true
    disableTrainingMode(site.value)
      .then(() => {
        showFlashMessage({ msg: $gettext("Training mode is disabled."), class: "is-success" })
        router.push({ name: "SiteSettings", params: route.params })
      }
    )
  }

  async function getData() {
    site.value = await getSiteByCmiId(siteCmiId)
    const canEdit = canEditSite(site.value)
    if (!site.value || !canEdit) {
      router.replace({ name: "SiteList" })
    }
    setCurrentViewContext(site.value, "site")
    purgeList.value = await getTrainingModePurgeList(site.value)
    pageReady.value = true
    hasPurgeData.value = Boolean(purgeList.value.children.length + purgeList.value.siteMealtimeAssessments.length)
  }

  getData()
</script>
<template>
  <article>
    <SiteHeader
      :site="site"
      :breadcrumb="{
        label: $gettext('Manage site'),
        urlParams: { name: 'SiteManage', params: { siteCmiId } },
      }"
    />
    <h1 class="title">
      {{ $gettext("Disable Training Mode") }}
    </h1>
    <div
      v-if="pageReady && hasPurgeData"
      class="message is-danger"
    >
      <ul class="message-body list">
        <li class="has-text-weight-bold">
          {{ $gettext("The following will be deleted if you disable training mode for this site.") }}
        </li>
        <li>
          {{ $gettext("Please review this list and make any updates.") }}
        </li>
        <li>
          {{ $gettext("When you are done, tap the button at the bottom.") }}
        </li>
      </ul>
    </div>
    <div
      v-else-if="pageReady && !hasPurgeData"
      class="message is-success"
    >
      <p class="message-body">
        {{ $gettext("This site has no training data to purge.") }}
      </p>
    </div>
    <template v-if="pageReady">
      <section
        v-if="hasPurgeData"
        class="panel is-primary"
      >
        <div class="panel-block">
          <h3>{{ $gettext("Children") }}</h3>
        </div>
        <template v-if="purgeList.children.length">
          <transition-group name="poof">
            <div
              v-for="child in purgeList.children"
              :key="child.id"
              class="panel-block is-justify-content-space-between"
            >
              <SiteDisableTrainingModeChild
                :child="child"
                :site="site"
                @remove-child.once="removeChildFromPurgeList"
              />
            </div>
          </transition-group>
        </template>
        <p
          v-else
          class="panel-block muted is-italic"
        >
          {{ $gettext("None") }}
        </p>
        <template v-if="site.isSiteMealtimeBestPracticeAssessmentEnabled">
          <div class="panel-block">
            <h3>{{ $gettext("Site-level mealtime best practice assessments") }}</h3>
          </div>
          <template v-if="purgeList.siteMealtimeAssessments.length">
            <div
              v-for="sma in purgeList.siteMealtimeAssessments"
              :key="sma.id"
              class="panel-block"
            >
              <strong>
                {{ $gettext("Assessment performed on %{ date }", { date: sma.date }) }}
              </strong>
            </div>
          </template>
          <p
            v-else
            class="panel-block muted is-italic"
          >
            {{ $gettext("None") }}
          </p>
        </template>
        <div class="panel-block is-justify-content-center">
          <a
            v-if="!formSubmitted"
            class="button is-fullwidth is-medium is-responsive is-primary"
            @click="purgeAndDisableTrainingMode"
          >
            {{ $gettext("Purge data and disable training mode") }}
          </a>
          <IconSpinner v-else />
        </div>
      </section>
      <section v-else>
        <div class="block has-text-centered">
          <a
            v-if="!formSubmitted"
            class="button is-fullwidth is-medium is-responsive is-primary"
            @click="purgeAndDisableTrainingMode"
          >
            {{ $gettext("Disable training mode") }}
          </a>
          <IconSpinner v-else />
        </div>
      </section>
    </template>
    <PageLoading v-else />
  </article>
</template>
<style scoped>
  h3 {
    font-size: 125%;
    color: hsl(0, 0%, 48%); /* Bulma grey */
  }
  .panel-block {
    position: relative;
  }
  svg.icon-spinner {
    max-width: 1.5em;
    max-height: 1.5em;
  }

  /* From https://stackoverflow.com/a/63596431/697143 */
  /* with sliding elements removed, more like a poof now.  */
  .poof-enter {
    opacity: 0;
  }

  .poof-move {
    transition: transform 1s;
  }

  .poof-enter-active {
    transition: opacity 0.5s;
  }

  .poof-leave-active {
    position: absolute;
    width: 100%;
    transition: opacity 0.5s;
    opacity: 0;
  }
</style>
